import axios from 'axios'
import {showFullScreenLoading, hideFullScreenLoading} from "./loading"

// variables 
// create an AXIOS instance
const instance = axios.create({
    // baseURL: 'http://127.0.0.1:5000/',
    baseURL: 'https://engage-proto.ut-capitole.fr/app/',  ////ENV PROD
    timeout: 10000
});
var viewName = "";
var loadingCount = 0;

// functions 
// show the loading icon
function addLoading(){
    if (loadingCount === 0 && viewName !== "") {
        showFullScreenLoading(viewName);
    }
    loadingCount++;
}
// close loaing icon
function isCloseLoading() {
    if (loadingCount > 0) {
        loadingCount--;
    }
    if (loadingCount === 0 && viewName !== "") {
        hideFullScreenLoading();
    }
}

// request interceptor
instance.interceptors.request.use(config=>{
    addLoading();
    return config;
},err=>{
    isCloseLoading();
    return Promise.reject(err);
});
// response interceptor
instance.interceptors.response.use(
    response=>{
    isCloseLoading();
    return response;
},err=>{
    isCloseLoading();
    return Promise.reject(err);
});

// send request to backend by http get
export function get(endpoint, param, loading){
    if(loading==true){
        viewName = ".publications_loading"
    }else{
        viewName = ""
    }
    return instance.get(endpoint, param);
}
// send request to backend by http post
export function post(endpoint, data, headers, loading){
    if(loading==true){
        viewName = ".publications_loading"
    }else{
        viewName = ""
    }
    
    return instance.post(endpoint, data, headers);
}


