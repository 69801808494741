<template>
  
    <div class="containerhome">
      
      <!-- <p>Welcome to ENGAGE.EU Publication Platform</p> -->
      <!-- <img src="./assets/logo.png" alt="lo"> -->
      <div class="main-part1">
        
        <div class="main">
            
            <div class="main-title">
                <p style="text-align: center;font-size: 25px;color: rgb(1, 169, 205);">Welcome to the Publications Platform</p>
                <p class="animate__animated animate__fadeIn animet__slower title">Start your search now</p>
                <p class="animate__animated animate__fadeIn animet__slower sub-title">1000+ resources waiting for you to discover</p>
            </div>
            
            
            <div class="main-search"> 
            <form class="main-research" v-on:submit.prevent="submitForm">
                <div class="main-type-div">
                    <select class="main-type" id="searchType" v-model="form.searchType" required> 
                        <!-- <option  value="all types"><p class="main-type-choice">all types(not available for now)</p></option> -->
                        <option value="all">All</option>
                        <option  value="title">title/keywords</option>
                        <!-- <option  value="organization">organisation</option> -->
                        <option  value="author">author</option>
                    </select>
                </div>
                <div>
                    <input type="text" id="searchText" name="main-research-text" class="main-research-text" :placeholder="placeholder_input(form.searchType)" v-model="form.searchText" required> 
                </div>
                <div class="main-research-sent-div">
                    <button class="main-research-send"><img class="main-icon-magnifier" src="../assets/magnifier-icon.png"></button>
                </div> 
            </form>

            </div>
            <p id="home_advenced_search" @click="routerto()" >advanced search</p>
        </div>
      
        <div class="main-logo">
            <img class="main-logo_photo" src="../assets/220525_ENGAGE_EuropakarteMAP.png" usemap="#your-map">
            <map name="your-map">
                <area shape="circle" coords="140,200,40" href="/onePartner?name=TU">
                <area shape="circle" coords="210,60,40" href="/onePartner?name=NHH">
                <area shape="circle" coords="255,230,40" href="/onePartner?name=UMA">
                <area shape="circle" coords="330,300,40" href="/onePartner?name=WU">
                <area shape="circle" coords="80,320,40" href="/onePartner?name=UT1">
                <area shape="circle" coords="410,360,40" href="/onePartner?name=UNWE">
                <area shape="circle" coords="195,410,40" href="/onePartner?name=LUISS">
                <area shape="circle" coords="390,110,40" href="/onePartner?name=HANKEN">
                <area shape="circle" coords="70,410,40" href="/onePartner?name=URL">
            </map>
        </div>
      </div>

        <div class="main-pubs">
            <div class="main-sub-title">
                <p class="main-pubs-title-text">New publications</p>
                
            </div><p class="update-date">Our database undergoes monthly updates</p>
            <div v-for="p in pubs" :key="p.title">
            <div class="main_pub">
                <Pub
                    :p = p />
            </div>
            </div>

           <!--  <div class="main_readmore">
                <div class="main_readmore">
                    
                    <router-link to="/Result"><button id="readmore" name="readmore" class="readmore">Read More</button></router-link>
                </div>
            </div> -->

        </div>

        <div class="main-chart">
            <div class="main-sub-title">
                <p>Key Numbers</p>
            </div>
            
            <div class="keynumbers_kpi"> 
            <table class="keynumbers_kpi_table"> 
                <tr> <td><p>Publications</p></td>
                    <td><p>Source Repositories</p></td>
                    <td><p>Topics</p></td>
                    <td><p>Publications in English</p></td>
                    <td><p>SDG related publications</p></td>
                </tr>
                <tr> <td><span class="keynumbers_kpi_numbers">{{ nbPubs }}</span></td>
                    <td><span class="keynumbers_kpi_numbers">{{ nbPartners }}</span></td>
                    <td><span class="keynumbers_kpi_numbers">{{ nbTopics }}</span></td>
                    <td><span class="keynumbers_kpi_numbers">{{ nbEnglish }}</span></td>
                    <td><span class="keynumbers_kpi_numbers">{{ nbsdgpubs }}</span></td>
                </tr>
            </table>
            </div>
            <DoughnutChart/>
            
            <div class="main_readmore">
                <router-link to="/keynumbers"><button id="readmore" name="readmore" class="readmore">Read More</button></router-link>
                
            </div>
        </div>

        <div class="main-partener">
            <div class="main-sub-title">
                <p>Partners</p>
            </div>
            <div class="main-partener-logos">
                <div v-for="pa in partners" :key="pa.id" >
                        <a class="footer-repository-a" :href="pa.urlhome"><img class="main_partener_logo_img" :src="srcImg(pa.img)"></a>
                </div>
                <!-- delete these two following lines once the partner add their information at db-->
                
                
            </div>
            
        </div>

      

      
    </div>
  </template>
  
<script>

// import axios from 'axios';
import DoughnutChart from '../components/Doughnut'
import Pub from '../components/Pub.vue'
import { get } from '../utils/request'
import store  from '../store'
  export default {
    name:'Home',
    components:{
        DoughnutChart,
        Pub
    },
    data(){
        return{
            nbsdgpubs:0,
            nbPubs: 0,
            nbPartners: 0,
            nbEnglish: 0,
            nbTopics: 0,
            partners:[],
            form:{
                searchType:'all',
                searchText:''
            },
            pubs:{}
        }
    },
    methods: {
        getMessage() {
            // const path = `${this.GLOBAL.BASE_URL}`;
            // axios.get(path)
            get(null,null,false)
            .then((res) => {
                // console.log(res.data);
                // this.partners = res.data.partnerDetails;
                this.pubs=res.data.newpubs
            })
            .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
            });
        },
        getKPI(){
            // const path = `${this.GLOBAL.BASE_URL}/chart`;
            // axios.get(path)
            get("chart", null, false)
            .then((res) => {
                // console.log(res.data);
                var topics = []
                for (var par in res.data){
                    this.nbPartners=this.nbPartners+1
                    this.nbPubs = this.nbPubs + res.data[par]['total_pubs_raw']
                    this.nbsdgpubs=this.nbsdgpubs+res.data[par]['total_pubs']
                    this.nbEnglish = this.nbEnglish + res.data[par]['total_pubs_en']
                    for (var topic in res.data[par]['pubs']){
                        if(topics.indexOf(res.data[par]['pubs'][topic]['_id'])==-1){
                            topics.push(res.data[par]['pubs'][topic]['_id'])
                        }
                    }
                    
                }
                this.nbTopics = topics.length
            })
            .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
            });
        },
        // getPartners(){
        //     // const path = `${this.GLOBAL.BASE_URL}/partnerinfo`;
        //     // axios.get(path)
        //     get("partnerinfo", null, false)
        //     .then((res) => {
        //         this.partners = res.data;

        //     })
        //     .catch((error) => {
        //     console.error(error);
        //     });
        // },
        getPartners(){
            setTimeout(() => {
            this.$set(this, 'partners', store.getSharedVariable());
        }, 2000) 
        },
        submitForm(){         
            this.$router.push({name:"Result",params: this.form})
            
        },
        type(creator){
            if(Array.isArray(creator)){
                return false;
            }else{
                return true;
            }
        },
        linkPerson(name){
            return store.linkPerson(name);
        },
        routerto(){
            this.$router.push({
                name:"Result",
                query:{
                    isAdvance:true
                }
            })
        },
        placeholder_input(text){
            if(text == "all"){
                return "title, keywords, author..."
            }else if(text == 'title'){
                return "title, keywords..."
            }else if(text == "author"){
                return "author..."
            }
        },
        srcImg(img){
            //function for create src of logo
            //data:image/png;base64,{code base64 of image}
            //data:image/jpeg;base64,{code base64 of image}
            //data:image/x-icon;base64,{code base64 of image}
            return "data:"+img.type+";base64,"+img.code
        },
    },
    created() {
        if(store.getSharedVariable().length==0){
            store.setSharedVariable();
        } 
        this.getMessage();
        this.getPartners();
        this.getKPI();
    }
  }
  </script>
  
  <style>
  
  </style>