<template>
    <div class="publications_loading" v-if="messageDisplay">
        <p class="publications_loading_text">{{ messageTexte }}</p>
    </div>
</template>
<script>
export default ({
    name:"LoadingMessage",
    props:{
        messageDisplay: Boolean,
        messageStatus: String // loading, no result, service error, network error
    },
    data(){
        return{
            messageTexte: ""
        }
    },
    methods:{
        showMessage(){
            if(this.messageStatus=="loading"){
                this.messageTexte = "";
            }else if(this.messageStatus=="no result"){
                this.messageTexte = "No result";
            }else if(this.messageStatus=="service error"){
                this.messageTexte = "Service not available"
            }else if(this.messageStatus=="network error"){
                this.messageTexte = "Please check your network";
            }else if(this.messageStatus == "too many results"){
                this.messageTexte = "Please refine your research, too many results in process ... ";
            }
        }
    },
    created(){
        this.showMessage();
    },
    watch:{
        messageStatus: {
            handler(){
                this.showMessage();
            }
        }
    }
})
</script>